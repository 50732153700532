import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './Root';
import { ThemeProvider } from './ThemeContext'; // Импортируйте ThemeProvider
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider>
    <Root />
    </ThemeProvider>
);


serviceWorkerRegistration.register();


reportWebVitals();

