import React, { useState, useRef, useEffect } from 'react';
import { Container, Nav, Navbar, Form, Modal } from 'react-bootstrap';
import { List, ClockHistory, Search, Person } from 'react-bootstrap-icons';
import { useTheme } from '../ThemeContext';
import { useAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
import SalesFeed from './SalesFeed'; 

const BottomNav = () => {
  const { theme } = useTheme();
  const { searchSales } = useAuth();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef(null);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [searchResults, setSearchResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';
  // Очистить поле поиска при открытии
  const handleSearchClick = () => {
    setShowSearchInput(true);
    setSearchText("");
  };

  // Закрыть поиск, если ничего не введено
  const handleBlur = () => {
    if (!searchText) {
      setShowSearchInput(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowSearchInput(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      let type;
      if (/^\d+$/.test(searchText)) {
        type = 'art';
      } else {
        type = 'name';
      }
      const data = await searchSales(type, searchText);
      
      setSearchResults(data); // Сохранение данных скидок
      setShowModal(true); // Открытие модального окна
      setSearchText("");
      setShowSearchInput(false);
    } else if (e.key === 'Escape') {
      setShowSearchInput(false);
    }
  };

  return (
    <Navbar fixed="bottom" bg={theme} variant={theme === 'dark' ? 'dark' : 'light'} className="navbar">
      <Container fluid>
        <Nav className="justify-content-around w-100">
          <Nav.Item>
            <Nav.Link href="/">
              <List size={24} />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/waiting-list">
              <ClockHistory size={24} />
            </Nav.Link>
          </Nav.Item>
          
          {isHomePage ? (
            <Nav.Item>
              {showSearchInput ? (
                <Form.Control
                  type="text"
                  inputMode="search"
                  placeholder="Арт. или название"
                  className={`search-input ${theme}`}
                  value={searchText}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  onClick={handleSearchClick}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                <Nav.Link onClick={() => setShowSearchInput(true)}>
                  <Search size={24} />
                </Nav.Link>
              )}
            </Nav.Item>
          ) : null}

          <Nav.Item>
            <Nav.Link href="/profile">
              <Person size={24} />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered >
        <Modal.Header closeButton className={themeClass}>
          <Modal.Title>Результаты поиска</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${themeClass} scrollable`} style={{ maxHeight: '56vh'}}>
          {searchResults.length>0?<SalesFeed sales={searchResults} type='modal'/>:<Modal.Title style={{color:'#f24942', textAlign:'center'}}>Ничего не найдено</Modal.Title>}
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};

export default BottomNav;
