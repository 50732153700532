import React from 'react';
import { useNavigate } from 'react-router-dom';
import TelegramLoginButton from 'react-telegram-login';
import { useAuth } from '../AuthContext';
import blackLoginPicture from '../../assets/blackLoginPicture.webp';
import whiteLoginPicture from '../../assets/whiteLoginPicture.webp';
import { useTheme } from '../../ThemeContext';
import { Modal, Button, CloseButton } from 'react-bootstrap';

const LoginPage = () => {
  const { theme } = useTheme();
  const backgroundImage = theme === 'dark' ? blackLoginPicture : whiteLoginPicture;
  const navigate = useNavigate();
  const { login, showModal, setShowModal } = useAuth();

  const handleTelegramResponse = async (response) => {
    await login(response);
    navigate('/');
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        className="align-self-center"
        style={{
          transform: 'translateY(50px)'  // смещение на 50px вниз от центра
        }}
      >
        <TelegramLoginButton
          botName="salew_bot"
          dataOnauth={handleTelegramResponse}
          lang="ru"
        />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className={theme === 'dark' ? 'text-white modal-dark' : 'text-dark modal-light'}
      >
        <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-light bg-light text-dark'}>
          <Modal.Title>Активация бота</Modal.Title>
          <CloseButton onClick={() => setShowModal(false)} className={theme === 'dark' ? 'btn-close-white' : 'btn-close-dark'} />
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
          <p>Для начала использования приложения необходимо активировать бота.</p>
          <p><a href="https://t.me/salew_bot" target="_blank" rel="noopener noreferrer">Перейдите по ссылке</a></p>
        </Modal.Body>

      </Modal>
    </div>
  );
};

export default LoginPage;