import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const CopySuccessMessage = () => (
  <div className="copy-success-message">Текст скопирован.</div>
);

const Codee = ({ children }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      setIsCopied(true);
    } catch (err) {
      console.error('Ошибка при копировании: ', err);
    }

    document.body.removeChild(textArea);
  };

  return (
    <>
      <code onClick={() => copyToClipboard(children[0] ? children[0] : children)}>{children}</code>
      {isCopied && ReactDOM.createPortal(<CopySuccessMessage />, document.body)}
    </>
  );
};

export default Codee;
