import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import { useTheme } from '../../ThemeContext';
import BottomNav from '../BottomNav';
import Codee from '../ui/Codee';
import { useNavigate } from 'react-router-dom';

const SubscriptionForm = () => {
  const { userInfo, isLoading, isAuthenticated, payWithBonus, payWithRegular } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [balance, setBalance] = useState(0);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const subscriptionPlans = [
    { days: 7, cost: 100 },
    { days: 30, cost: 199 },
    { days: 90, cost: 499 },
    { days: 180, cost: 999 }
  ];
  const subscriptionCost = 199;

  useEffect(() => {
    const initializeAuth = async () => {
      if (isAuthenticated && !isLoading) {
        setBalance(userInfo.balance);
        if (userInfo.email) {
          setEmail(userInfo.email);
        }
      }
    };
    initializeAuth();
  }, [userInfo, isLoading, isAuthenticated]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!emailPattern.test(newEmail)) {
      setEmailError('Неверный формат email');
    } else {
      setEmailError('');
    }
  };

  const handleBonusPayment = async () => {
    try {
      const data = await payWithBonus(userInfo.user_id);
      if (data.status) {
        alert('Оплата бонусами прошла успешно!');
        navigate('/');
      } else {
        alert("Не хватает денег на балансе");
      }

    } catch (err) {
      setError(err.message);
    }
  };

  const handleRegularPayment = async () => {
    try {
      const data = await payWithRegular(email, selectedPlan.days, selectedPlan.cost);
      setPaymentLink(data.paymentLink);
      setShowModal(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';
  const headerStyle = {
    borderRadius: '10px',
    padding: '20px',
    margin: '10px',
    backgroundColor: theme === 'dark' ? '#212529' : '#f8f9fa',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  };

  return (
    <Container className={`pt-3 pb-5 ${themeClass} scrollable`}>
      <Row className="justify-content-md-center">
        <Col>
          <Card className={`d-flex align-items-center justify-content-center text-center ${themeClass} shadow-lg`} style={headerStyle}>
            <Card.Body>
              {balance >= subscriptionCost ? (
                <>
                  <Card.Title>Вы можете оплатить бонусами!</Card.Title>
                  <Card.Text>
                    Ваш реферальный баланс: {balance.toFixed(2)} руб.
                    <br />
                    Ваш ID: <Codee>{userInfo ? userInfo.user_id : null}</Codee>
                  </Card.Text>
                  <Button variant="success" onClick={handleBonusPayment} disabled={loading}>
                    Оплата бонусами 30 дней 199 руб.
                  </Button>
                  <hr />
                  <div className="d-flex flex-wrap justify-content-center">
                    {subscriptionPlans.map((plan) => (
                      <Button
                        key={plan.days}
                        variant="outline-primary"
                        className="m-2"
                        onClick={() => handlePlanSelect(plan)}
                        disabled={loading}
                      >
                        {plan.days} дней - {plan.cost} руб.
                      </Button>
                    ))}
                  </div>
                </>
              ) : paymentLink ? (
                <>
                  <Card.Title>Оплата подписки на {selectedPlan.days} дней в Salew_Bot</Card.Title>
                  <Alert variant="success" className="mt-3 p-4">
                    <Card.Text>
                      Нажимая на кнопку <strong>Оплатить {selectedPlan.cost} рублей</strong>, вы соглашаетесь с&nbsp;
                      <a href="https://salew-bot.ru/agreement.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                        Пользовательским соглашением
                      </a>
                      &nbsp;и&nbsp;
                      <a href="https://salew-bot.ru/politika-obrabotki-personalnyh-dannyh.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                        Политикой обработки персональных данных
                      </a>.
                    </Card.Text>

                  </Alert>
                  <Button
                    variant="primary"
                    href={paymentLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-3"
                  >
                    Оплатить {selectedPlan.cost} рублей
                  </Button>
                </>

              ) : (
                <>
                  <Card.Title>Выберите тариф</Card.Title>
                  <Card.Text>
                    Для оплаты подписки бонусами, на реферальном балансе должно быть от 199 руб., на вашем реферальном балансе: {balance.toFixed(2)} руб.
                    <br />
                    Ваш ID: <Codee>{userInfo ? userInfo.user_id : null}</Codee>
                  </Card.Text>
                  <hr />
                  <div className="d-flex flex-wrap justify-content-center">
                    {subscriptionPlans.map((plan) => (
                      <Button
                        key={plan.days}
                        variant="outline-primary"
                        className="m-2"
                        onClick={() => handlePlanSelect(plan)}
                        disabled={loading}
                      >
                        {plan.days} дней - {plan.cost} руб.
                      </Button>
                    ))}
                  </div>
                </>
              )}
              {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-dark'}>
          <Modal.Title>Введите Email для оплаты</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Email адрес</Form.Label>
              <Form.Control
                type="email"
                inputMode="email"
                placeholder="Введите email"
                className={`search-input ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'} ${emailError ? 'is-invalid' : ''}`}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              {emailError && <div className="invalid-feedback">{emailError}</div>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={theme === 'dark' ? 'border-top border-secondary bg-dark text-white' : 'border-top border-secondary bg-light text-dark'}>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Отмена
          </Button>
          <Button variant="primary" onClick={handleRegularPayment} disabled={loading || !email || emailError}>
            Оплатить
          </Button>
        </Modal.Footer>
      </Modal>
      <BottomNav />
    </Container>
  );
};

export default SubscriptionForm;
