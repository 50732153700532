import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext';
import { useTheme } from './ThemeContext';
import App from './App';
import LoginPage from './components/pages/LoginPage';
import SubscriptionForm from './components/pages/pay';
import ProfilePage from './components/pages/ProfilePage';
import WaitingListPage from './components/pages/WaitingListPage';
import LoadingIndicator from './components/ui/LoadingIndicator';
import { Modal, Button } from 'react-bootstrap';
import { getMessaging } from "firebase/messaging";


const messaging = getMessaging();


const ProtectedRoute = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

const Root = () => {
  const { theme } = useTheme();
  const themeClass = theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark';
  document.body.className = `App ${themeClass}`;

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  // Регистрация Service Worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          // Проверка, есть ли уже ожидающий активации Service Worker
          if (registration.waiting) {
            setShowUpdateModal(true); // Показать модальное окно для обновления
          }

          // Отслеживание обновлений Service Worker
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                // Новый Service Worker готов к активации
                setShowUpdateModal(true); // Показать модальное окно для обновления
              }
            };
          };
        })
        .catch((error) => {
          console.error('Ошибка при регистрации Service Worker:', error);
        });

      // Обработка смены контроллера (обновление Service Worker)
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('Service Worker обновлён, перезагрузка страницы...');
        window.location.reload(); // Перезагрузить страницу для применения новой версии
      });
    }
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    setIsIos(isIOS);

    const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
    setIsStandalone(isInStandaloneMode);

    if (isIOS && !isInStandaloneMode) {
      setShowInstallPrompt(true);
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallPrompt(false);
      });
    }
  };

  const handleCloseModal = () => {
    setShowInstallPrompt(false);
  };

  const handleUpdateApp = () => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    });
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/subscribe" element={<SubscriptionForm />} />
          <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="/waiting-list" element={<ProtectedRoute><WaitingListPage /></ProtectedRoute>} />

        </Routes>
      </Router>

      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)} centered>
        <Modal.Header closeButton className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
          <Modal.Title>Обновление доступно</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
          <p>Доступна новая версия приложения. Обновите страницу для применения изменений.</p>
          <Button variant="primary" onClick={handleUpdateApp}>Обновить</Button>
        </Modal.Body>
      </Modal>

      <Modal show={showInstallPrompt && !isStandalone} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
          <Modal.Title>Установите наше приложение</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
          {isIos ? (
            <div>
              <p>Для установки этого приложения на iOS:</p>
              <ol>
                <li>Откройте сайт в Safari (если открыли через телеграм, нажмите на три точки вверху справа и "открыть в Safari").</li>
                <li>Нажмите кнопку "Поделиться" внизу браузера.</li>
                <li>Выберите "На экран домой".</li>
                <li>Подтвердите установку.</li>
              </ol>
            </div>
          ) : (
            <>
              <p>Установите наше приложение для удобного использования!</p>
              <Button variant="primary" onClick={handleInstallClick}>Установить</Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </AuthProvider>
  );
};

export default Root;
