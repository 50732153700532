import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BottomNav from '../BottomNav';
import { useTheme } from '../../ThemeContext';


const ProfilePage = () => {
  const { theme } = useTheme();

  // Определите классы в зависимости от темы и добавьте тени
  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';
  
  
  // Стили для заголовка и основного содержимого, можно добавить в CSS файл
  const headerStyle = {
    padding: '1rem', // Отступ для заголовка
    marginBottom: '1rem', // Нижний отступ
    borderRadius: '0.5rem', // Скругление углов
  };
  
  return (
    <>
      <Container className={`pt-3 pb-5 ${themeClass}`}>
        <Row className="mb-3">
          <Col>
            <div className={`profile-header rounded ${themeClass} shadow-lg`} style={headerStyle}>
              <h1 className="text-center">Лист Ожидания</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <BottomNav />
    </>
  );
};

export default ProfilePage;
