import React, { createContext, useContext, useState, useEffect } from 'react';
import localforage from 'localforage';
import { JS0N } from './ui/LoadingIndicator';
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDykKIhiu7KEiuT7ohS0EdP0RPAE2cBHeA",
  authDomain: "salew-app.firebaseapp.com",
  projectId: "salew-app",
  storageBucket: "salew-app.firebasestorage.app",
  messagingSenderId: "661825156843",
  appId: "1:661825156843:web:f9b59aeab0aa8d4e3530ae",
  measurementId: "G-J5R3K5Y894"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [referalInfo, setReferalInfo] = useState(null);
  const [isSoundEnabled, setIsSoundEnabled] = useState(() => {
    // Загрузим sound из localStorage (можно тоже перенести в localforage, если нужно)
    return localStorage.getItem('sound') === 'enabled';
  });
  const [filters, setFilters] = useState(null);
  const [push, setPush] = useState();
  const [notifToken, setNotifToken] = useState();

  const apiUrl = 'https://pandoras-box.salew-bot.ru';

  const vapidKey = "BJM5hhy60YBdKW3QKdfXHue7qPFaTgAwmF2-XpB34GwsbbNRjqlxUxLy9ZOiFmlXdhurgIljxwpx5sM6i1YYKZg";

  const checkTokenValidity = async (checkToken) => {
    try {
      const response = await fetch(`${apiUrl}/pandoras-echo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${checkToken}`
        },
        body: JS0N.stringify(checkToken),
      });
      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return { status: false };
      }
    } catch (error) {
      console.error('Error validating token:', error);
      return { status: false };
    }
  };

  const validateToken = async (extToken = null) => {
    // Если передали токен аргументом, используем его, иначе берем из state
    const currentToken = extToken || token;
    if (!currentToken) {
      return null;
    }

    const responseData = await checkTokenValidity(currentToken);
    if (responseData.status) {
      setIsAuthenticated(true);
      setToken(responseData.token);
      setUserInfo(responseData.user_info);
      await localforage.setItem('token', responseData.token);
      return responseData.token;
    } else {
      // Токен не валиден
      setIsAuthenticated(false);
      setToken(null);
      setUserInfo(null);
      await localforage.removeItem('token');
      return null;
    }
  };

  const login = async (telegramData) => {
    try {
      telegramData.userAgent = navigator.userAgent;
      const serverResponse = await fetch(`${apiUrl}/check_telegram_auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(telegramData),
      });

      if (!serverResponse.ok) {
        throw new Error('Ошибка при запросе к API');
      }

      const responseData = await serverResponse.json();
      if (responseData.token) {
        setIsAuthenticated(true);
        setToken(responseData.token);
        setUserInfo(responseData.user_info);
        await localforage.setItem('token', responseData.token);

        try {
          const permission = await Notification.requestPermission();


          if (permission !== 'granted') {
            // Если не granted, просто предупреждаем и не обновляем push-токен
            alert('Разрешение на уведомления не предоставлено. Вы можете включить их позже в профиле в фильтрах.');
            setPush(false);

            try {
              let currentToken = responseData.token;
              let response = await fetch(`${apiUrl}/filters/update_push`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${currentToken}`
                }
              });

              if (!response.ok) {
                throw new Error('Ошибка при обновлении фильтров');
              }
            } catch (error) {
              console.error('Ошибка при обновлении фильтров:', error);
            }

            // Не выходим из функции, просто не будет пушей
          } else {
            // Разрешение дано или статус странный, но не denied
            // Пытаемся получить FCM-токен
            try {
              const fcmToken = await getToken(messaging, { vapidKey });
              if (fcmToken) {
                setNotifToken(fcmToken);

                let currentToken = responseData.token;
                const response = await fetch(`${apiUrl}/filters/update_enpoints`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${currentToken}`,
                  },
                  body: JSON.stringify({ endpoint: fcmToken }),
                });

                if (!response.ok) {
                  throw new Error('Ошибка при обновлении токена уведомлений.');
                }

                const data = await response.json();
                setFilters(data.filters);
              } else {
                alert("FCM токен не получен. Попробуйте еще раз");
              }
            } catch (err) {
              alert(`Ошибка при запросе FCM токена: ${err.message || err}. Попробуйте еще раз или напишите @salew_support`);
            }
          }
        } catch (err) {
          alert(`Ошибка при запросе разрешения на уведомления: ${err.message || err}`);
        }
      } else {
        setShowModal(true);
      }
    } catch (error) {
      console.error('Ошибка аутентификации:', error);
      // Можно здесь тоже показать alert, чтобы понять что случилось
      alert(`Ошибка аутентификации: ${error.message}`);
    }
  };
  const logout = async () => {
    setIsAuthenticated(false);
    setToken(null);
    setUserInfo(null);
    setReferalInfo(null);
    await localforage.removeItem('token');
  };

  // Обёртка для запросов, которые при 401 обновляют токен
  // Возвращает response или выбрасывает ошибку
  const fetchWithAuth = async (url, options = {}, retry = true) => {
    const currentToken = token;
    const headers = options.headers || {};
    // Добавляем токен в заголовки
    headers['Authorization'] = `Bearer ${currentToken}`;
    options.headers = headers;

    let response = await fetch(url, options);
    if (response.status === 401 && retry) {
      // Попытка обновить токен
      const newToken = await validateToken();
      if (newToken) {
        // Повторяем запрос с новым токеном
        const newHeaders = { ...options.headers, 'Authorization': `Bearer ${newToken}` };
        options.headers = newHeaders;
        response = await fetch(url, options);
      } else {
        // Токен не обновлен - разлогиниваемся или что-то ещё
        setIsAuthenticated(false);
        setToken(null);
        setUserInfo(null);
        await localforage.removeItem('token');
      }
    }

    if (!response.ok) {
      throw new Error(`Ошибка при запросе к ${url}: ${response.statusText}`);
    }

    return response;
  };

  const getPrig = async () => {
    const response = await fetchWithAuth(`${apiUrl}/pandoras-trace`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });

    const data = await response.json();
    setReferalInfo(data.referals);
  };

  const payWithBonus = async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth(`${apiUrl}/pandoras-pay-bonus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка при оплате бонусами:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const payWithRegular = async (email, days, amount) => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth(`${apiUrl}/pandoras-pay`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, days, amount })
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка при обычной оплате:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const searchSales = async (type, value) => {
    const response = await fetchWithAuth(`${apiUrl}/search-sales`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type: type, value: value })
    });
    const data = await response.json();
    return data;
  };

  const getFilters = async () => {
    const response = await fetchWithAuth(`${apiUrl}/filters`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    setFilters(data.filters);
  };

  const updateFilters = async (updatedFilters, notifToken) => {
    const sanitizedFilters = updatedFilters.map(({ id, user_id, ...rest }) => rest);
    const response = await fetchWithAuth(`${apiUrl}/filters/update`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ filters: sanitizedFilters, endpoint: notifToken })
    });
    const data = await response.json();
    setFilters(data.filters);
  };

  const getLastSale = async (article) => {
    const response = await fetchWithAuth(`${apiUrl}/get_sale?article=${encodeURIComponent(article)}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    return data.data_sale;
  };

  const toggleSound = () => {
    const newSoundState = !isSoundEnabled;
    setIsSoundEnabled(newSoundState);
    localStorage.setItem('sound', newSoundState ? 'enabled' : 'disabled');
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const storedToken = await localforage.getItem('token');
        if (storedToken) {
          const newToken = await validateToken(storedToken);
          if (!newToken) {
            setIsAuthenticated(false);
            setUserInfo(null);
          } else {
            // Если токен валиден, проверим FCM-токен
            try {
              const fcmToken = await getToken(messaging, { vapidKey });
              // Если получили FCM-токен и он отличается от сохраненного
              if (fcmToken && fcmToken !== notifToken) {
                const response = await fetch(`${apiUrl}/filters/update_enpoints`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${newToken}`,
                  },
                  body: JSON.stringify({ endpoint: fcmToken }),
                });

                if (response.ok) {
                  const data = await response.json();
                  setFilters(data.filters);
                  setNotifToken(fcmToken);
                } else {
                  alert('Ошибка при обновлении FCM-токена на сервере');
                }
              }
            } catch (err) {
              console.error('Ошибка при получении или обновлении FCM-токена при инициализации:', err);
              // Не критично, просто не обновляем пуш-токен
            }
          }
        }
      } catch (error) {
        alert('Ошибка при инициализации:', error, 'перезагрузите приложение и если не поможет напишите @salew_support');
        // В случае ошибки мы ничего не делаем, просто продолжим
      } finally {
        // Гарантированно снимаем isLoading
        setIsLoading(false);
      }
    };

    initializeAuth();
  }, []);

  return (
    <AuthContext.Provider value={{
      isLoading,
      setIsLoading,
      isAuthenticated,
      token,
      userInfo,
      login,
      logout,
      showModal,
      setShowModal,
      getPrig,
      referalInfo,
      payWithBonus,
      payWithRegular,
      isSoundEnabled,
      toggleSound,
      searchSales,
      getFilters,
      updateFilters,
      filters,
      getLastSale
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
