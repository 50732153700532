import React, { useState, useEffect } from 'react';
import './App.css';
import { useTheme } from './ThemeContext';
import BottomNav from './components/BottomNav';
import SalesFeed from './components/SalesFeed';
import ConnectionStatus, { WebSocketProvider, useWebSocket } from './components/WebSocketContext';
import { useLocation } from 'react-router-dom';

function App() {
  const { theme } = useTheme();
  const themeClass = theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark';
  document.body.className = `App ${themeClass}`;

  const location = useLocation();
  const [targetArticle, setTargetArticle] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const articleFromUrl = params.get('article');
    if (articleFromUrl) {
      setTargetArticle(articleFromUrl);
    } else {
      setTargetArticle(null); // Если нет параметра article, просто null
    }
  }, [location]);

  return (
    <WebSocketProvider>
      <MainContent targetArticle={targetArticle} />
    </WebSocketProvider>
  );
}

const MainContent = ({ targetArticle }) => {
  const { sales } = useWebSocket();
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    if (sales !== null) {
      setSalesData(sales);
    }
  }, [sales]);

  return (
    <div className="main-content">
      <ConnectionStatus />
      <SalesFeed sales={salesData} type='ws' targetArticle={targetArticle} />
      <BottomNav />
    </div>
  );
};

export default App;
