import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Используем состояние для хранения текущей темы
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') ||
    (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  );

  const updateStatusBarColor = (currentTheme) => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    const newColor = currentTheme === 'dark' ? '#212529' : '#F8F9FA'; // Цвета Bootstrap для темной и светлой темы
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', newColor);
    }
  };

  // Функция для переключения темы
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme); // Сохраняем тему в localStorage
    updateStatusBarColor(newTheme); // Обновляем статус-бар при смене темы
  };

  // Вызываем updateStatusBarColor при инициализации для установки правильного цвета статус-бара
  useEffect(() => {
    updateStatusBarColor(theme);

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      const newTheme = mediaQuery.matches ? 'dark' : 'light';
      setTheme(newTheme);
      updateStatusBarColor(newTheme);
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []); // Пустой массив зависимостей, чтобы вызвать эффект только один раз при монтировании компонента

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
