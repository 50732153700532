import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, CloseButton, Card, Form, Tooltip, OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import BottomNav from '../BottomNav';
import { useTheme } from '../../ThemeContext';
import { VolumeUp, VolumeMute, MoonStars, Sliders, Sun, Share, InfoCircle } from 'react-bootstrap-icons';
import Codee from '../ui/Codee';
import { useAuth } from '../AuthContext';
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import localforage from 'localforage'; // Добавляем localforage

const firebaseConfig = {
  apiKey: "AIzaSyDykKIhiu7KEiuT7ohS0EdP0RPAE2cBHeA",
  authDomain: "salew-app.firebaseapp.com",
  projectId: "salew-app",
  storageBucket: "salew-app.firebasestorage.app",
  messagingSenderId: "661825156843",
  appId: "1:661825156843:web:f9b59aeab0aa8d4e3530ae",
  measurementId: "G-J5R3K5Y894"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// Стандартные фильтры по умолчанию
const defaultFilters = [
  {
    "categ": "Мужчинам",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Женщинам",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Детям",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Все для девочек",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Все для мальчиков",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Дом",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Красота",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Аксессуары",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Электроника",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Игрушки",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Мебель",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Товары для взрослых",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Продукты",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Бытовая техника",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Зоотовары",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Спорт",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Автотовары",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Книги",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Ювелирные изделия",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Для ремонта",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Сад и дача",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Здоровье",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Канцтовары",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Другое",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "wb",
    "prod": true,
    "sale": 0,
    "ostatok": 1
  },
  {
    "categ": "Ozon",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "ozon",
    "prod": null,
    "sale": 0,
    "ostatok": null
  },
  {
    "categ": "Detmir",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "detmir",
    "prod": null,
    "sale": 0,
    "ostatok": null
  },
  {
    "categ": "Yandex",
    "status_categ": true,
    "push": false,
    "user_status": false,
    "marketplace": "yandex",
    "prod": null,
    "sale": 0,
    "ostatok": null
  }
];

const ProfilePage = () => {
  const { theme, toggleTheme } = useTheme();
  const { filters, getFilters, updateFilters, userInfo, getPrig, referalInfo, isAuthenticated, isLoading, logout, isSoundEnabled, toggleSound } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [localFilters, setLocalFilters] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const [errors, setErrors] = useState([]);
  const [notifToken, setNotifToken] = useState();

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [pendingDiscount, setPendingDiscount] = useState(null);
  const [pendingFilterIndex, setPendingFilterIndex] = useState(null);
  const [hasShownDiscountInfo, setHasShownDiscountInfo] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [hasBackupFilters, setHasBackupFilters] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      if (isAuthenticated && !isLoading) {
        await getPrig();
        await getFilters();
      }
    };
    initializeAuth();
  }, []);
  useEffect(() => {
    if (filters) {
      setLocalFilters(filters);
    }
  }, [filters]);

  useEffect(() => {
    localforage.getItem('hasShownDiscountInfo').then((value) => {
      if (value === true) {
        setHasShownDiscountInfo(true);
      }
    });
  }, []);
  useEffect(() => {
    localforage.getItem('backupFilters').then((backup) => {
      if (backup) setHasBackupFilters(true);
    });
  }, []);

  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';

  const subscriptionStatus = userInfo.status
    ? <div className={`subscription-status basic-subscription`}>Подписка Активна до {userInfo.until} по МСК </div>
    : <div className={`subscription-status no-subscription`}>Подписка закончилась {userInfo.until} по МСК </div>;

  const copyRefLink = () => {
    navigator.clipboard.writeText(`https://t.me/SaleW_bot?start=${userInfo.user_id}`)
      .then(() => alert("Ссылка скопирована!"))
      .catch((err) => console.error('Ошибка при копировании: ', err));
  };

  const handleShare = async () => {
    const shareData = {
      title: 'Присоединяйтесь к SaleW боту!',
      text: 'Переходи по ссылке и покупай подписку!',
      url: `https://t.me/SaleW_bot?start=${userInfo.user_id}`,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.url);
        alert('Ссылка скопирована в буфер обмена!');
      }
    } catch (err) {
      console.error('Ошибка при попытке поделиться: ', err);
    }
  };

  const requestFCMToken = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        alert('Разрешение на получение уведомлений не предоставлено');
        return null;
      }

      const vapidKey = "BJM5hhy60YBdKW3QKdfXHue7qPFaTgAwmF2-XpB34GwsbbNRjqlxUxLy9ZOiFmlXdhurgIljxwpx5sM6i1YYKZg";


      // Получение токена FCM
      const token = await getToken(messaging, { vapidKey });

      if (token) {
        //alert(`FCM Token получен: ${token}`);
        return token;
      } else {
        alert('Не удалось получить FCM токен. Попробуйте еще раз или напишите @salew_support');
        return null;
      }
    } catch (err) {
      console.error('Ошибка при запросе FCM токена:', err);
      alert(`Ошибка при запросе FCM токена: ${err.message || err} . Попробуйте еще раз или напишите @salew_support`);
      return null;
    }
  };

  const validateFilter = (filter, index) => {
    const error = {};



    // Валидация остатка
    if (filter.ostatok !== undefined && filter.ostatok < 0) {
      error.ostatok = "Остаток не может быть отрицательным";
    }

    // Обновляем ошибки для текущего фильтра
    const newErrors = [...errors];
    newErrors[index] = error;
    setErrors(newErrors);

    // Если ошибок нет, возвращаем true
    return Object.keys(error).length === 0;
  };
  const handleFilterChange = async (index, field, value) => {
    const updatedFilters = [...localFilters];
    // Если меняем скидку — сначала показываем модалку, не меняем сразу состояние
    if (field === 'sale') {
      // Если еще не показывали окно, показать.
      if (!hasShownDiscountInfo) {
        setPendingDiscount(value);
        setPendingFilterIndex(index);
        setShowInfoModal(true);
        return;
      } else {
        // Если окно уже показывалось, можно сразу менять
        updatedFilters[index][field] = value;
      }
    } else {
      updatedFilters[index][field] = value;
    }

    setLocalFilters(updatedFilters);
    setIsModified(true);

    if (field === 'push' && value === true) {
      const fcmToken = await requestFCMToken();
      if (fcmToken) {
        setNotifToken(fcmToken);
      } else {
        updatedFilters[index].push = false;
        setLocalFilters(updatedFilters);
      }
    }

    validateFilter(updatedFilters[index], index);
  };

  // Функция вызывается при нажатии "Понятно" в модалке
  const handleInfoModalConfirm = () => {
    const updatedFilters = [...localFilters];
    updatedFilters[pendingFilterIndex].sale = pendingDiscount;
    setLocalFilters(updatedFilters);
    setIsModified(true);

    // Закрываем модалку
    localforage.setItem('hasShownDiscountInfo', true);
    setHasShownDiscountInfo(true);
    setShowInfoModal(false);
    setPendingDiscount(null);
    setPendingFilterIndex(null);
  };
  // Функция вызывается при нажатии "Непонятно" в модалке
  const handleInfoModalCancel = () => {
    // Просто закрываем модалку без изменений
    setShowInfoModal(false);
    setPendingDiscount(null);
    setPendingFilterIndex(null);
  };

  const handleSaveFilters = async () => {
    const isValid = localFilters.every((filter, index) => validateFilter(filter, index));
    if (isValid) {
      await updateFilters(localFilters, notifToken);
      setShowFiltersModal(false);
      setIsModified(false);
    } else {
      alert("Пожалуйста, исправьте ошибки перед сохранением");
    }
  };

  const handleModalClose = () => {
    if (isModified) {
      if (window.confirm('Сохранить изменения перед закрытием?')) {
        const isValid = localFilters.every((filter, index) => validateFilter(filter, index));
        if (isValid) {
          handleSaveFilters(); // Сохраняем и закрываем окно
          setShowFiltersModal(false); // Закрываем окно только если все валидно
        } else {
          alert("Пожалуйста, исправьте ошибки перед закрытием.");
          // Окно останется открытым для исправления ошибок
        }
      } else {
        setShowFiltersModal(false); // Закрываем окно без сохранения
      }
    } else {
      setShowFiltersModal(false); // Закрываем, если не было изменений
    }
  };

  const handleResetFilters = async () => {
    await localforage.setItem('backupFilters', localFilters);
    setHasBackupFilters(true);
    setLocalFilters(defaultFilters);
    setIsModified(true);
    setShowToast(true);
  };
  const handleCloseToast = () => setShowToast(false);

  // Возврат к сохранённым в backup фильтрам
  const handleRestoreFilters = async () => {
    const backup = await localforage.getItem('backupFilters');
    if (backup) {
      setLocalFilters(backup);
      setIsModified(true);
      // Удаляем backup из localforage
      await localforage.removeItem('backupFilters');
      setHasBackupFilters(false);
    }
  };

  return (
    <>
      <Container className={`pt-3 pb-5 ${themeClass} scrollable`}>
        <Row className="mb-3">
          <Col>
            <Card className={`shadow-lg ${themeClass}`}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">ID: <Codee>{userInfo.user_id}</Codee></h4>
                <Button onClick={() => window.location.href = '/subscribe'} variant="primary">
                  Оплатить подписку
                </Button>
              </Card.Body>
              <Card.Footer className="text-center">
                {subscriptionStatus}
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className={`${themeClass} shadow-lg`} style={{ padding: '1rem', borderRadius: '0.5rem' }}>
              <h2 className="text-center">Реферальная программа</h2>
              <div className={`d-flex justify-content-around`}>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }}>
                  <h1 className="text-center" style={{ fontWeight: 800 }}>{referalInfo ? referalInfo[0] : null}</h1>
                  <h6 className="text-center" style={{ color: 'grey', fontWeight: 200 }}>Рефералов</h6>
                </div>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }}>
                  <h1 className="text-center" style={{ fontWeight: 800 }}>{referalInfo ? referalInfo[1] : null}</h1>
                  <h6 className="text-center" style={{ color: 'grey', fontWeight: 200 }}>Активных подписок</h6>
                </div>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }}>
                  <h1 className="text-center" style={{ fontWeight: 800 }}>{userInfo.balance}₽</h1>
                  <h6 className="text-center" style={{ color: 'grey', fontWeight: 200 }}>Баланс</h6>
                </div>
              </div>

              <div className={`d-flex justify-content-around`} style={{ marginTop: '30px' }}>
                <Button variant="outline-primary" onClick={handleShare} className="mx-2" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px', height: '70px' }}>
                  Поделиться ссылкой <Share size={20} />
                </Button>
                <Button variant="outline-primary" onClick={() => setModalShow(true)} className="mx-2" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px', height: '70px' }}>
                  Условия
                </Button>
              </div>
            </div>
          </Col>
        </Row>


        <Row className="mb-3">
          <Col>
            <div className={`d-flex justify-content-around ${themeClass} shadow-lg`} style={{ borderRadius: '0.5rem', minHeight: '100px' }}>
              <Button variant="transparent" onClick={toggleSound} style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }} className={themeClass}>
                {isSoundEnabled ? <VolumeUp size={40} /> : <VolumeMute size={40} />}
              </Button>
              <Button variant="transparent" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }} className={themeClass} onClick={toggleTheme}>
                {theme === 'dark' ? <Sun size={40} /> : <MoonStars size={40} />}
              </Button>
              <Button variant="transparent" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }} className={themeClass} onClick={() => setShowFiltersModal(true)}>
                <Sliders size={40} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className={`d-flex justify-content-center`} style={{ borderRadius: '0.5rem', minHeight: '100px' }}>
              <Button variant="outline-danger" onClick={() => logout()} className="mx-2" style={{ alignItems: 'center', justifyContent: 'center', width: '30%', padding: '0 10px', height: '40px', marginTop: '20px' }}>Выйти</Button>
            </div>
          </Col>
        </Row>
      </Container>

      <BottomNav />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className={theme === 'dark' ? 'text-white modal-dark' : 'text-dark modal-light'}
      >
        <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-black'}>
          <Modal.Title>Реферальная программа</Modal.Title>
          <CloseButton onClick={() => setModalShow(false)} className={theme === 'dark' ? 'btn-close-white' : 'btn-close-black'} />
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'text-black bg-light'}>
          <p><strong>За каждую оплату подписки приглашенного Вами человека,</strong> на Ваш бонусный счёт начисляется <strong>25% от суммы покупки подписки</strong> приглашенного!</p>
          <p>
            А также вы и приглашенный получаете <strong>+2 дня подписки</strong> за каждого нового пользователя, который впервые заходит в бота по вашей реферальной ссылке.
          </p>
          <p>Вывести вы сможете на любую карту российского банка, в боте <a href="https://t.me/salew_bot" target="_blank" rel="noopener noreferrer" className={theme === 'dark' ? 'text-white' : 'text-dark'}>@Salew_Bot</a>, после того как накопится более 500 руб. за приглашения</p>
          <p>Приглашенным считается тот, кто первый раз перешел в бот по вашей реферальной ссылке!</p>
          <p>Материалы для рекламы вы можете использовать из канала <a href="https://t.me/salew_catch" target="_blank" rel="noopener noreferrer" className={theme === 'dark' ? 'text-white' : 'text-dark'}>@salew_catch</a></p>
          <p className="text-warning">Внимание: В период акционных предложений за оплату подписки по специальной цене реферальные бонусы не начисляются, если иное не определено условиями акции</p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFiltersModal}
        onHide={handleModalClose}
        className={theme === 'dark' ? 'text-white modal-dark' : 'text-black modal-light'}
        size="lg"
      >
        <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-black'}
        >
          <Modal.Title>Фильтры</Modal.Title>
          <CloseButton
            onClick={handleModalClose}
            className={theme === 'dark' ? 'btn-close-white' : 'btn-close-black'}
          />
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-black'}>
          <Container fluid>
            <Row>
              {localFilters.map((filter, index) => (
                <Col key={index} xs={12} sm={6} md={6} lg={6} xl={4} className="mb-4">
                  <Card className={`h-100 ${theme === 'dark' ? 'transparent-black' : 'transparent-white'}`} style={{ paddingTop: '0px', opacity: filter.status_categ ? 1 : 0.3 }}>
                    <Card.Body>
                      <Form>
                        <Form.Group controlId={`filter-${index}`}>
                          <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem', textAlign: 'center', display: 'block' }}>
                            {filter.categ}
                            <Form.Check
                              type="switch"
                              id={`status-categ-${index}`}
                              checked={filter.status_categ}
                              onChange={(e) => handleFilterChange(index, 'status_categ', e.target.checked)}
                            />
                          </Form.Label>

                          <Form.Check
                            type="switch"
                            id={`push-${index}`}
                            label="Push уведомления"
                            checked={filter.push}
                            onChange={(e) => handleFilterChange(index, 'push', e.target.checked)}
                          />

                          {/* Скидка и подсказка */}
                          <Form.Label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            Скидка
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id={`tooltip-sale-${index}`}>
                                  Скидка рассчитывается, исходя из минимальной цены диапазона.
                                  Если выбрана, к примеру, 10%, а минимальная цена — 100 рублей,
                                  то товар отобразится при цене 90 рублей или ниже.
                                  Если скидка 0% — показываются все товары, в том числе без диапазона.
                                  При выборе значения больше 0% будут отображаться только товары,
                                  у которых есть реальная скидка относительно минимальной цены диапазона.
                                  Для Ozon, Detmir и Yandex скидка считается от зачеркнутой (старой) цены.
                                </Tooltip>
                              }
                            >
                              <span style={{ cursor: 'pointer', color: '#0d6efd' }}>
                                <InfoCircle />
                              </span>
                            </OverlayTrigger>
                          </Form.Label>

                          <Form.Select
                            value={filter.sale}
                            onChange={(e) => handleFilterChange(index, 'sale', parseInt(e.target.value))}
                            isInvalid={errors[index]?.price}
                          >
                            {[...Array(10)].map((_, i) => {
                              const val = i * 10;
                              return <option key={val} value={val}>{val}%</option>;
                            })}
                          </Form.Select>

                          {errors[index]?.price && <div className="text-danger">{errors[index]?.price}</div>}

                          {/* Валидация остатка */}
                          {filter.categ !== 'Ozon' && filter.categ !== 'Detmir' && filter.categ !== 'Yandex' && (
                            <>
                              <Form.Label>Остаток</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="От"
                                value={filter.ostatok}
                                onChange={(e) => handleFilterChange(index, 'ostatok', parseInt(e.target.value))}
                                isInvalid={errors[index]?.ostatok}
                              />

                              <Form.Check
                                type="switch"
                                id={`prod-${index}`}
                                label="Склад продавца"
                                checked={filter.prod}
                                onChange={(e) => handleFilterChange(index, 'prod', e.target.checked)}
                              />
                              {errors[index]?.ostatok && <div className="text-danger">{errors[index]?.ostatok}</div>}
                            </>
                          )}
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className={`${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-black'} d-flex justify-content-between px-4`}>
          <div>
            <Button variant="secondary" onClick={handleResetFilters}>
              Сбросить фильтры
            </Button>
            {hasBackupFilters && (
              <Button variant="info" onClick={handleRestoreFilters} className="ms-2">
                Вернуть фильтры
              </Button>
            )}
          </div>
          <Button variant="primary" onClick={handleModalClose}>
            Сохранить
          </Button>
        </Modal.Footer>

      </Modal>

      {/* Новое модальное окно для пояснения при изменении скидки */}
      <Modal
        show={showInfoModal}
        onHide={handleInfoModalCancel}
        className={theme === 'dark' ? 'text-white modal-dark' : 'text-dark modal-light'}
      >
        <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-black'}>
          <Modal.Title>Информация о скидке</Modal.Title>
          <CloseButton onClick={handleInfoModalCancel} className={theme === 'dark' ? 'btn-close-white' : 'btn-close-black'} />
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-black'}>
          <h5 className="mb-3">Как считается скидка:</h5>
          <ul className="mb-3">
            <li>Скидка считается <strong>от минимальной цены ДИАПАЗОНА.</strong></li>
            <li>Например, если минимальная цена была 100 ₽ и вы указали скидку 10%, то товар отобразится только если его текущая цена ≤ 90 ₽.</li>
            <li>При скидке 0% отображаются все товары, даже те, у которых нет диапазона цен.</li>
            <li>Если скидка больше 0%, мы показываем только товары, которые действительно стали дешевле своей минимальной цены, а <strong>НЕ ОТ ЗАЧЕРКНУТОЙ ЦЕНЫ.</strong></li>
            <li>Для Ozon, Detmir и Yandex скидка рассчитывается от зачёркнутой (старой) цены.</li>
          </ul>

          <h6 className="text-warning">
            Пример: Если вчера утюг стоил 500 ₽ (минимальная цена),потом цена выросла, и сегодня снова 500 ₽,
            то при скидке больше 0% он не будет показан, так как не стал дешевле.
            Чтобы увидеть товар, его цена должна упасть ниже когда-то зафиксированного минимума.
          </h6>
        </Modal.Body>

        <Modal.Footer className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-black'}>
          <Button variant="secondary" onClick={handleInfoModalCancel}>
            Непонятно
          </Button>
          <Button variant="primary" onClick={handleInfoModalConfirm}>
            Понятно
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="top-end" className="p-3">
        <Toast show={showToast} onClose={handleCloseToast} autohide delay={5000}>
          <Toast.Header>
            <strong className="me-auto">Информация</strong>
          </Toast.Header>
          <Toast.Body>
            Фильтры сброшены! Для применения сохраните фильтры. Если хотите вернуть прежние фильтры, нажмите "Вернуть фильтры".
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ProfilePage;
